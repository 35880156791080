import React from 'react';
import { Avatar, Divider, ListItemButton, ListItemAvatar, ListItemText } from '@mui/material';
import { PairingTypes } from '@walletconnect/types';

interface PairingItemProps {
  pairing: PairingTypes.Settled;
  onSelect: (topic: string) => void;
  disabled: boolean;
}

const PairingItem: React.FC<PairingItemProps> = (props) => {
  const { pairing, onSelect, disabled } = props;

  const handleSelect = React.useCallback(() => {
    onSelect(pairing.topic);
  }, [pairing, onSelect]);

  const { name, description, icon } = React.useMemo(() => {
    const { metadata } = pairing.state;

    return {
      name: metadata?.name || pairing.topic,
      description: metadata?.description || '',
      icon: metadata?.icons[0] || '',
    };
  }, [pairing]);

  return (
    <>
      <ListItemButton onClick={handleSelect} disabled={disabled}>
        <ListItemAvatar>
          <Avatar src={icon} />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={description}
          primaryTypographyProps={{ noWrap: true }}
        />
      </ListItemButton>
      <Divider light />
    </>
  );
};

export default PairingItem;
