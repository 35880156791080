import { IClientRegistry } from '../types/IClientRegistry';
import Client from '@walletconnect/client';
import { DEFAULT_LOGGER, DEFAULT_RELAY_PROVIDER } from '../utils/constants';

export class ClientRegistry implements IClientRegistry {
  private static instance: IClientRegistry;

  public static async getInstance(): Promise<IClientRegistry> {
    if (!ClientRegistry.instance) {
      const client = await Client.init({
        logger: DEFAULT_LOGGER,
        relayProvider: DEFAULT_RELAY_PROVIDER,
      });

      ClientRegistry.instance = new ClientRegistry(client);
    }

    return ClientRegistry.instance;
  }

  private constructor(private readonly client: Client) {}

  getClient(): Client {
    return this.client;
  }
}
