import { createSlice } from '@reduxjs/toolkit';
import { ConnectIdentityState } from './connectIdentityTypes';
import {
  createPairing,
  initApp,
  requestIAMProfile,
  sessionDisconnect,
} from './connectIdentityActions';

const initialState: ConnectIdentityState = {
  loading: false,
  session: null,
  pairings: [],
  activePairingRequest: null,
  disconnecting: false,
  profileRequest: false,
  account: null,
};

export const connectIdentitySlice = createSlice({
  name: 'connectIdentity',
  initialState,
  reducers: {
    setPairings: (state, action) => {
      state.pairings = action.payload;
    },
    setSession: (state, action) => {
      state.session = action.payload;
    },
    resetPairingRequest: (state) => {
      state.activePairingRequest = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestIAMProfile.pending, (state) => {
      state.profileRequest = true;
    });
    builder.addCase(requestIAMProfile.rejected, (state) => {
      state.profileRequest = false;
    });
    builder.addCase(requestIAMProfile.fulfilled, (state, action) => {
      state.profileRequest = false;
      if (action.payload) {
        state.account = action.payload;
      }
    });
    builder.addCase(sessionDisconnect.pending, (state) => {
      state.disconnecting = true;
    });
    builder.addCase(sessionDisconnect.rejected, (state) => {
      state.disconnecting = false;
    });
    builder.addCase(sessionDisconnect.fulfilled, (state) => {
      state.disconnecting = false;
      state.session = null;
      state.account = null;
    });

    builder.addCase(initApp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(initApp.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(initApp.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload) {
        state.session = action.payload;
      }
    });

    builder.addCase(createPairing.pending, (state, action) => {
      state.activePairingRequest = action.meta.requestId;
    });
    builder.addCase(createPairing.rejected, (state, action) => {
      const { requestId } = action.meta;

      if (state.activePairingRequest !== requestId) {
        return;
      }

      state.activePairingRequest = null;
    });
    builder.addCase(createPairing.fulfilled, (state, action) => {
      const { requestId } = action.meta;

      if (state.activePairingRequest !== requestId) {
        return;
      }

      if (action.payload) {
        state.session = action.payload;
      }

      state.activePairingRequest = null;
    });
  },
});

export const { setPairings, setSession, resetPairingRequest } = connectIdentitySlice.actions;
