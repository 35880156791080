import { Button, Container } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import PairingsList from '../PairingsList/PairingsList';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { createPairing } from '../../store/connectIdentity/connectIdentityActions';
import { Redirect } from 'react-router-dom';
import { resetPairingRequest } from '../../store/connectIdentity/connectIdentitySlice';

const WalletSetupPage = () => {
  const { pairings, activePairingRequest, session } = useAppSelector(
    (state) => state.connectIdentity
  );
  const dispatch = useAppDispatch();

  const pairingDisabled = !!activePairingRequest;

  const handleCreatePairing = React.useCallback(() => {
    dispatch(createPairing());
  }, [dispatch]);

  const handlePairingSelect = React.useCallback(
    (topic: string) => {
      dispatch(createPairing({ topic }));
    },
    [dispatch]
  );

  const cancelPairing = React.useCallback(() => {
    dispatch(resetPairingRequest());
  }, [dispatch]);

  if (session) {
    return <Redirect to="/session" />;
  }

  return (
    <Container maxWidth="sm">
      <Button
        disabled={pairingDisabled}
        sx={{ mt: 2, mr: 1 }}
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleCreatePairing}
      >
        New Pairing
      </Button>
      {!!activePairingRequest && (
        <Button
          startIcon={<CloseIcon />}
          size="small"
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={cancelPairing}
        >
          Cancel Pairing Request
        </Button>
      )}
      <PairingsList pairings={pairings} onSelect={handlePairingSelect} disabled={pairingDisabled} />
    </Container>
  );
};

export default WalletSetupPage;
