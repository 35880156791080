import React from 'react';
import { PairingTypes } from '@walletconnect/types';
import { List, ListSubheader } from '@mui/material';
import { map } from 'lodash';
import PairingItem from './PairingItem';

interface PairingsListProps {
  pairings: PairingTypes.Settled[];
  onSelect: (topic: string) => void;
  disabled: boolean;
}

const PairingsList: React.FC<PairingsListProps> = (props) => {
  const { pairings, onSelect, disabled } = props;
  return (
    <List subheader={<ListSubheader>Pairings</ListSubheader>}>
      {map(pairings, (pairing) => (
        <PairingItem
          pairing={pairing}
          key={pairing.topic}
          onSelect={onSelect}
          disabled={disabled}
        />
      ))}
    </List>
  );
};

export default PairingsList;
