import { ActorName, IAMCandid } from '@origyn-sa/origyn_sdk';

export const DEFAULT_RELAY_PROVIDER = 'wss://relay.walletconnect.org';
export const DEFAULT_LOGGER = 'debug';

export const APP_METADATA = {
  name: 'IAM Manager',
  description: 'React App for IAM profile management',
  url: 'http://localhost:3000',
  icons: ['https://avatars.githubusercontent.com/u/59101585?s=200&v=4'],
};

export enum ICMethods {
  signData = 'ic_sign',
  getPubKey = 'get_pub_key',
}

export const METHODS = [ICMethods.signData, ICMethods.getPubKey];
export const IC_CHAIN_ID = 'ic:1';
export const CHAINS = [IC_CHAIN_ID];
export const IAM_HOST = 'https://42x3r-5iaaa-aaaah-qalla-cai.raw.ic0.app/';
export const IAM_CANISTER_ID = '42x3r-5iaaa-aaaah-qalla-cai';

export const ACTORS = [
  {
    name: ActorName.IAM,
    host: IAM_HOST,
    canisterId: IAM_CANISTER_ID,
    idl: IAMCandid.IAM_DID,
  },
];
