import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {
  requestIAMProfile,
  sessionDisconnect,
} from '../../store/connectIdentity/connectIdentityActions';
import { Redirect } from 'react-router-dom';

const UserSessionPage = () => {
  const dispatch = useAppDispatch();
  const { disconnecting, session, profileRequest, account } = useAppSelector(
    (state) => state.connectIdentity
  );

  const disableControls = profileRequest || disconnecting;

  const handleDisconnect = React.useCallback(() => {
    dispatch(sessionDisconnect());
  }, [dispatch]);

  const handleProfileRequest = React.useCallback(() => {
    dispatch(requestIAMProfile());
  }, [dispatch]);

  if (!session) {
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 2 }}>
        <Button
          sx={{ mr: 1 }}
          variant="outlined"
          color="error"
          onClick={handleDisconnect}
          disabled={disableControls}
        >
          Disconnect
        </Button>
        <Button variant="outlined" disabled={disableControls} onClick={handleProfileRequest}>
          Request IAM Profile
        </Button>
      </Box>
      {account && (
        <Box sx={{ mt: 1 }}>
          <Typography>{account.id}</Typography>
          <Typography>{account.username}</Typography>
          <Typography>{account.email}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default UserSessionPage;
