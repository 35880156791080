import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientRegistry } from '../../services/ClientRegistry';
import { resetPairingRequest, setPairings, setSession } from './connectIdentitySlice';
import { CLIENT_EVENTS } from '@walletconnect/client';
import { ACTORS, APP_METADATA, CHAINS, METHODS } from '../../utils/constants';
import { SignalTypes, PairingTypes, SessionTypes } from '@walletconnect/types';
import QRCodeModal from '@walletconnect/qrcode-modal';
import { ERROR } from '@walletconnect/utils';
import { isEmpty } from 'lodash';
import { RootState } from '../store';
import { ActorsPool, IAMRepository, WalletConnectIdentity } from '@origyn-sa/origyn_sdk';

export const initApp = createAsyncThunk('identity/initApp', async (_, thunkAPI) => {
  const client = (await ClientRegistry.getInstance()).getClient();

  const updatePairings = () => {
    thunkAPI.dispatch(setPairings(client.pairing.values));
  };

  updatePairings();

  client.on(CLIENT_EVENTS.pairing.deleted, updatePairings);
  client.on(CLIENT_EVENTS.pairing.created, updatePairings);
  client.on(CLIENT_EVENTS.pairing.updated, updatePairings);

  client.on(CLIENT_EVENTS.pairing.proposal, (proposal: PairingTypes.Proposal) => {
    QRCodeModal.open(proposal.signal.params.uri, () => {
      thunkAPI.dispatch(resetPairingRequest());
    });
  });

  client.on(CLIENT_EVENTS.session.deleted, (deletedSession: SessionTypes.Settled) => {
    const state = thunkAPI.getState() as RootState;
    const { session } = state.connectIdentity;

    if (!session) {
      return;
    }

    if (session.topic === deletedSession.topic) {
      thunkAPI.dispatch(setSession(null));
    }
  });

  try {
    if (!isEmpty(client.session.topics)) {
      return await client.session.get(client.session.topics[0]);
    }
  } catch (e) {
    console.error(e);
  }
});

export const createPairing = createAsyncThunk(
  'identity/createPairing',
  async (pairing?: SignalTypes.ParamsPairing) => {
    const client = (await ClientRegistry.getInstance()).getClient();

    try {
      return await client.connect({
        metadata: APP_METADATA,
        pairing,
        permissions: {
          blockchain: {
            chains: CHAINS,
          },
          jsonrpc: {
            methods: METHODS,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      QRCodeModal.close();
    }
  }
);

export const sessionDisconnect = createAsyncThunk('session/disconnect', async (_, thunkAPI) => {
  const client = (await ClientRegistry.getInstance()).getClient();

  const state = thunkAPI.getState() as RootState;

  const { session } = state.connectIdentity;

  if (!session) {
    return;
  }

  return client.disconnect({
    topic: session.topic,
    reason: ERROR.USER_DISCONNECTED.format(),
  });
});

export const requestIAMProfile = createAsyncThunk('iam/requestProfile', async (_, thunkAPI) => {
  const client = (await ClientRegistry.getInstance()).getClient();

  const state = thunkAPI.getState() as RootState;

  const { session } = state.connectIdentity;

  if (!session) {
    return;
  }

  const identity = await WalletConnectIdentity.createIdentity(client, session.topic);

  await ActorsPool.init(ACTORS, identity);

  const iam = new IAMRepository();

  return iam.getProfile();
});
