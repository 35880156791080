import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import WalletSetupPage from './pages/WalletSetupPage';
import { useAppDispatch, useAppSelector } from '../store/store';
import { initApp } from '../store/connectIdentity/connectIdentityActions';
import { Box, CircularProgress } from '@mui/material';
import UserSessionPage from './pages/UserSessionPage';

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.connectIdentity);

  React.useEffect(() => {
    dispatch(initApp());
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/session">
          <UserSessionPage />
        </Route>
        <Route path="/">
          <WalletSetupPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;
